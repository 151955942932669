// var shopModalSlider_spFlick = function (){
var $ = require( "./../../../../bower_components/jquery/dist/jquery.js" );
var easing = require( 'jquery-easing');

$(function(){
  // ===============================================
  // モーダルウィンドウ 処理
  // ===============================================
  (function modal(){
    var target = '';
    var $wrapper = $(".l-wrapper");
    var $modal = $(".c-modal");
    var $modal_area = $(".c-modal-area");
    var bgBlur = 'p-bg__blur';
    var $close = $(".is-close");
//

// }
    // function ieFilter_add(){
    //   if($.browser.msie){
    //     $modal_area.addClass('is-ie__bg');
    //   }
    // }
    // function ieFilter_remover(){
    //   if($.browser.msie){
    //     $modal_area.removeClass('is-ie__bg');
    //   }
    // }
    var ie = window.navigator.appName.toLowerCase().indexOf("microsoft") > -1;
    ie |= window.navigator.userAgent.toLowerCase().indexOf("trident/7.0") > -1;
    $(".c-modal__btn").on('click', function(e){
      target = $(this).get(0).className.split(" ")[1];
      target = $(".c-modal." + target);
      if ( target.is(":hidden") ) {
        target.fadeIn(600);
        $wrapper.addClass(bgBlur);
        // ieFilter_add();
        if (ie) {
          $modal_area.fadeIn('500', function() {
            $(this).addClass('is-ie__bg');
          });
        }
      } else {
        target.hide();
        $wrapper.removeClass(bgBlur);
        // ieFilter_remover();
        if (ie) {
          $modal_area.fadeOut('500', function() {
            $(this).removeClass('is-ie__bg');
          });
        }
      }
    });
    $close.on('click', function(){
      $modal.hide();
      $wrapper.removeClass(bgBlur);
      if (ie) {
        $modal_area.fadeOut('500', function() {
          $(this).removeClass('is-ie__bg');
        });
      }
    });
  })();
  // ===============================================
  // フリックスライダー / PCスライダー 処理
  // ===============================================
  $.getJSON("/wp-content/themes/massimo-f/common/js/shop.json" , function(data) {
    // ===============================================
    // フリックスライダー
    // ===============================================
    function flickEvent(shop_id){
      var flick_class = '.p-flick';
      var flickCurrent_class = '.p-flickCurrent';
      var flickCurrent = 'p-flickCurrent';
      var flickList = $(flick_class + shop_id);       //フリック領域の要素
      var spanList = $(flickCurrent_class + shop_id);
      var shop = data["shop" + shop_id];
      var imgListCount = data["shop" + shop_id].length;
      var current_id = shop_id;
      var i = 0;

      var threshold = 80; // スワイプ発生とみなす距離
      var oneMoveDistance = 330; // 一回のスライドで動かす距離

      // 使う要素を準備
      var doc = $(document);

      for(i; i < imgListCount; i++) {
        var slider_width = 330 * imgListCount;
        var flickLists = '<img src="' + shop[i].item + '" width="300">';
        var spanLists = '<span></span>';
        flickList.append($('<div class="item">').html(flickLists));
        spanList.append(spanLists);
        var flickListImg   = $(flick_class + shop_id + ' div');    //フリック画像の設置要素
        flickList.css('width',slider_width + 'px');
        var spanListActive = $(flickCurrent_class + shop_id + ' span');
        var flickPhoto = $('.p-flickPhoto' + shop_id);
      }

      // .flickPhotoそれぞれについて処理する
      flickPhoto.each(function(){

        // ===============================================
        // スライドに関する処理
        // ===============================================

        // スライドに関する要素群を準備
        var container = $(this);
        var inner = container.find('.p-flickInner');
        var items = flickListImg;

        // ドット
        // var $dots = container.find('.dotContainer span');
        var $dots = spanListActive;
        var foo = $('.p-flickCurrent__container span:first-child').addClass(flickCurrent);
        foo.addClass(flickCurrent);

        // 現在のleft値を保存するための変数
        var currentLeft = 0;
        // 最大left値
        var maxLeft = 0;
        // 最小left値
        var minLeft = -1 * oneMoveDistance * (items.length - 1);

        // 一段階左へスライドさせる関数
        var toLeft = function(){

          var nextLeft = currentLeft - oneMoveDistance;

          if(nextLeft < minLeft) {
            return;
          }

          // 写真らをスライド
          currentLeft = nextLeft;
          inner.stop().animate({ left: nextLeft }, 200);

          // ドットを移動
          var currentDot = $dots.filter(flickCurrent_class);
          currentDot.removeClass(flickCurrent);
          currentDot.next().addClass(flickCurrent);

        };

        // 一段階右へスライドさせる関数
        var toRight = function(){

          var nextLeft = currentLeft + oneMoveDistance;

          if(nextLeft > maxLeft) {
            return;
          }

          // 写真らをスライド
          currentLeft = nextLeft;
          inner.stop().animate({ left: nextLeft }, 200);

          // ドットを移動
          var currentDot = $dots.filter(flickCurrent_class);
          currentDot.removeClass(flickCurrent);
          currentDot.prev().addClass(flickCurrent);

        };

        // ===============================================
        // スワイプに関連する処理
        // ===============================================

        // 座標を保存するための変数
        var startX, currentX, startY, currentY;

        var sleeping = false; // スワイプ検知休止フラグ
        var touching = false; // タッチ中か否か
        var horizontalSwipe = false; // 横方向のスワイプか否か
        var verticalSwipe = false; // 縦方向のスワイプか否か

        // スワイプ方向の検知

        function checkSwipeDirection() {
          // スワイプ開始座標より10px以上下に移動していたら縦移動
          if((startY + 10) < currentY) {
            verticalSwipe = true;
            return;
          }
          if((startY - 10) > currentY) {
            verticalSwipe = true;
            return;
          }
          // スワイプ開始座標より5px以左右に移動していたら横移動
          if((startX + 5) < currentX) {
            horizontalSwipe = true;
            return;
          }
          if((startX - 5) > currentX) {
            horizontalSwipe = true;
            return;
          }
        }

        // スワイプ評価

        var evalSwipe = function(event){

          // スワイプ方向がまだ未検出の場合、方向をチェック
          if(!verticalSwipe && !horizontalSwipe){
            checkSwipeDirection();

            // もし横方向のスワイプであればスクロールを止める
            // （touchstartかtouchmoveでpreventDefaultすればスクロールとまる）
            if(horizontalSwipe) {
              event.preventDefault();
            }
            // 縦方向のスワイプであれば、それは通常の画面スクロールと
            // 判断し、もうスワイプの検知を行わなくする
            if(verticalSwipe) {
              sleeping = true;
            }
          }

          // 左右へのスワイプが発生したかをチェック
          if((currentX - startX) > threshold) {
            toRight(); // 右へスライド
            sleeping = true;
          }
          if((startX - currentX) > threshold) {
            toLeft(); // 左へスライド
            sleeping = true;
          }

        };

        // touchstartイベントの処理

        container.on('touchstart', function(event){
          var touches = event.originalEvent.touches;
          if(touches.length>1){
            return;
          }
          var touch = touches[0];
          startX = touch.pageX; // X座標を保存
          startY = touch.pageY; // Y座標を保存
          touching = true;
        });

        // touchmoveイベントの処理

        doc.on('touchmove', function(event){
          if(!touching) {
            return;
          }
          if(sleeping) {
            return;
          }
          var touch = event.originalEvent.touches[0];
          currentX = touch.pageX; // X座標を保存
          currentY = touch.pageY; // Y座標を保存
          evalSwipe(event);
        });

        // touchend, touchcancelイベントの処理

        doc.on('touchend touchcanel', function(){
          if(!touching) {
            return;
          }
          // 各種フラグをリセット
          touching = false;
          sleeping = false;
          horizontalSwipe = false;
          verticalSwipe = false;
        });

      }); // photos each

    }
    // ===============================================
    // PCスライダー
    // ===============================================
    function sliderEvents(shop_id){
      var shop = data["shop" + shop_id];
      var slider = '.p-slider';
      var $sliderList = $('.p-slider' + shop_id);
      var $wrapSlider = $('.p-wrapSlider' + shop_id);
      var imgListCount = data["shop" + shop_id].length;
      var _imgNum = 0;    //画像の枚数
      var _imgSize = 0;   //画像のサイズ
      var _current = 0;   //現在の画像
      var i = 0;

      //各ボタンの配置
      $wrapSlider.append('<a href="#" class="p-sliderBtn__prev"><i class="fa fa-angle-left fa-4x"></i></a><a href="#" class="p-sliderBtn__next"><i class="fa fa-angle-right fa-4x"></i></a>');
      var $btn_next = $('.p-sliderBtn__next');
      var $btn_prev = $('.p-sliderBtn__prev');

      for(i; i < imgListCount; i++) {
        var sliderLists = '<img src="' + shop[i].item + '">';
        $sliderList.append($('<li>').html(sliderLists));
        var _imgSizeImg   = $(slider + shop_id + ' li');    //フリック画像の設置要素
      }

        //画像サイズ取得
      _imgSize = _imgSizeImg.width();

      //メイン画像の数だけ繰り返す
      _imgSizeImg.each(function(){
          //画像をずらして外に配置
          $(this).css('margin-left', -_imgSize);
          //画像の数だけページネーションボタンを作成
          if (_imgNum == _current) {
              //currentだったらアクティブ、メインの画像は表示
              $(this).css('margin-left', '0px');
          }
          //ループの数をカウントして_imgNumに入れる
          _imgNum++;
      });

      //ボタンをクリック
      $btn_next.click(function(e){
          e.preventDefault();
          imageMove(_current +1);
      });
      $btn_prev.click(function(e){
          e.preventDefault();
          imageMove(_current -1);
      });

      function imageMove(next) {
          var pos;
          if (_current < next) {
              pos = -_imgSize;
          } else {
              pos = _imgSize;
          }

          //次の画像が最後なら1枚目、１枚目なら最後
          if (next == _imgNum) {
              next = 0;
          } else if(next == -1) {
              next = (_imgNum-1);
          }

          //次の画像を動かす
          _imgSizeImg.eq(next)
          //次の画像を次の位置に配置
          .css("margin-left", pos)
          .animate({
              marginLeft: "0"
          },"fast");

          //現在の画像を動かす
          _imgSizeImg.eq(_current)
          .animate({
              marginLeft: -pos
          },"fast");

          //現在の番号を次の番号にする。
          _current = next;
      }
    }

    $(function(){
      var shopNumber = ['01','02','03','04','05','06','07','08','09','10','11','12','13'];  // 増えた場合は追加
      for (var count = 0; count < shopNumber.length; count++) {
        flickEvent(shopNumber[count]);
        sliderEvents(shopNumber[count]);
      }
    });
  });
});

// };
// module.exports = shopModalSlider_spFlick;
