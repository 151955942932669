var $ = require( "./../../../../bower_components/jquery/dist/jquery.js" );
var easing = require( 'jquery-easing');
var smoothScroll = function (opt){
  opt = opt || {};
  opt.speed = opt.speed || 400;
  opt.offset = opt.offset || 0;
  opt.scroll = opt.scroll || 100;
  opt.easing = opt.easing || 'swing';
  $(function(){
    $('a[href^=#]').on('click', function(e){
      e.preventDefault();
      var href = $(this).attr('href'),
          speed = opt.speed,
          easing = opt.easing,
          target = $(href == "#" || href == "" ? 'html' : href),
          animateParam = {
            scrollTop: target.offset().top - opt.offset
          };
      $('body,html').animate(animateParam, speed, easing);
    });
    var smoothBtn = $('#page-top');
    smoothBtn.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > opt.scroll) {
        smoothBtn.fadeIn();
      } else {
        smoothBtn.fadeOut();
      }
    });
  });
};
module.exports = smoothScroll;