var $ = require( "./../../../../bower_components/jquery/dist/jquery.js" );
var easing = require( 'jquery-easing');
var mainSlider = function (){
$(function(){
  loopanime_start();
});

function loopanime_start(){
  var timerId;
  var imgNo = 4;        //画像枚数
  var currentNo = 0;
  var targetNo = 0;
  var fspeeds=1000;     //切り替え時間

  //画像のalt
  var alts = [
    "マッシモ店舗01",
    "マッシモ店舗02",
    "マッシモ店舗03",
    "マッシモ店舗04"
  ];

  (function(){
    for(var i=1; i<=imgNo; i++){
      $('<li id="rImg'+i+'">').appendTo($('#mainSlider'));

      var img = $('<img>').attr('src','/wp-content/themes/massimo-f/common/images/img_visual0'+i+'.jpg');//画像パスとファイル名指定
      img.attr('alt',alts[i-1]);
      img.load(imgLoaded.call(img, i));
    }
  })();

  function imgLoaded(no){
    var obj = $('#rImg'+no);
    obj.children('img').remove();
    obj.append($(this));
    // $('<a href="'+links[no-1]+'" />').append(this).appendTo(obj);

    if(no==1) loop();
  }

  function loop(){
    if(currentNo!=0) $('#rImg'+currentNo).fadeOut(fspeeds);

    if(targetNo==0) currentNo = ++currentNo>imgNo?1:currentNo;
    else currentNo = targetNo;
    targetNo = 0;

    $('#rImg'+currentNo).fadeIn(fspeeds);
    timerId = setTimeout(loop, 6000)
  }
}


  // $(function(){
  //   $.getJSON("/wp-content/themes/massimo-f/common/js/main.json" , function(data) {
      // var $pc_slider = $("#mainSlider");
      // var $sp_slider = $("#sp-mainSlider");
      // var nowVisibleBox = 1;
      // var count = data.response.length;
      // var intervalTime = 5000;
      // var fadeTime = 800;
      // var timer = false;
      // for(var i = 1; i < count; i++) {
      //   var img_pc = '<img src="' + data.response[i].image_pc + '">';
      //   var img_sp = '<img src="' + data.response[i].image_sp + '">';
      //   $pc_slider.append($("<li>").html(img_pc));
      //   $sp_slider.append($("<li>").html(img_sp));
      //   var countBoxes = $('#mainSlider li').size();
      //   console.log(countBoxes);
      //   var sp_countBoxes = $('#sp-mainSlider li').size();
      // }
      // function slideShow(slide) {
      //   if (nowVisibleBox === countBoxes) {
      //     // slide.find('li:first-child').show();
      //     slide.find('li:visible').fadeOut(fadeTime);
      //     slide.find('li:first-child').fadeIn();
      //     nowVisibleBox = 2;
      //     console.log('ほげ');
      //   } else{
      //     slide.find('li:visible').fadeOut(fadeTime);
      //     slide.find('li:visible').next().fadeIn(fadeTime);
      //     nowVisibleBox++;
      //   }
      // }
      // $(function() {
      //   'use strict';
      //   setInterval(function() {
      //     slideShow($pc_slider);
      //     slideShow($sp_slider);
      //   }, intervalTime);
      // });
  //   });
  // });
};
module.exports = mainSlider;