/*
================================================================================
* javascript information
* file name  : utlilty.js (共有のスクリプト)
================================================================================
*/
var $ = require( "./../../../bower_components/jquery/dist/jquery.js" );
var easing = require( 'jquery-easing');

/* ============================
 *
  GoogleAnalytics
 *
============================ */
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-66067638-1', 'auto');
  ga('send', 'pageview');

/* ============================
 *
  smoothScroll
 *
============================ */
var smoothScroll = require('./vendor/smoothScroll.js');
smoothScroll({
  speed : 400,
  offset : 0,
  scroll : 100,
  easing : 'swing'
});
/* ============================
 *
  more btn
 *
============================ */
$(function(){
  // 文字制限 （blog title）
  function cutTextEvent(){
    var $title = $('.p-blogListBox__title');
    var cutFigure = '25';
    var afterTxt = ' …';
    $title.each(function(){
      var textLength = $(this).text().length;
      var textTrim = $(this).text().substr(0,(cutFigure));
      if(cutFigure < textLength) {
        $(this).html(textTrim + afterTxt).css({visibility:'visible'});
      } else if(cutFigure >= textLength) {
        $(this).css({visibility:'visible'});
      }
    });
  }
  function mouseoverEvent(){
    var $mouseoverTarget = $(".p-blogListBox a"),
        read_txt = '.is-read__txt',
        read_txt_span = '.is-read__txt span',
        addClass_txt = 'p-blogListBox__txt';
    $mouseoverTarget.mouseover(function(){
      $(this).find(read_txt).addClass(addClass_txt);
      $(this).find(read_txt_span).css('display','block');
    }).mouseout(function() {
      $(this).find(read_txt).removeClass(addClass_txt);
      $(this).find(read_txt_span).css('display','none');
    });
  }
  var setArea = $("#content"),
  setArea_page = $("#page_content"),
  setArea_archive = $("#archive_content"),
  loadTxt = 'Now Loading...', // Loading中の表示テキスト
  btnTxt = 'もっと見る', // ボタンテキスト
  id_btnMore = '#btnMore',
  html_btn = '<div id="btnMore" class="p-blog__btn clearfix" style="overflow: hidden; clear: both"><a herf="#">' + btnTxt + '</a></div>',
  loadNum = 4, // 読み込む個数
  fadeSpeed = 500; // フェードスピード

  setArea.after(html_btn);
  var setMore = setArea.next(id_btnMore);

  setArea_page.after(html_btn);
  var setMore_top = setArea_page.next(id_btnMore);

  setArea_archive.after(html_btn);
  var setMore_archive = setArea_archive.next(id_btnMore);

  // var category_name = 'm_naebo';
  var page_name = $('#content').data('name');
  var category_urls = '/wp-json/posts?filter[orderby]=&filter[category_name]=' + page_name;
  var post_url =  '/wp-json/posts';
          var url = location.href;
              url = url.match(/(\d{4})\/(\d{2}$)/);
              url = RegExp.$1 + RegExp.$2;
  function ajax_list(urls,area){
    $.ajax({
      url: urls,
      dataType: 'json',
      success : function(data){
        var dataLengh = data.length,
        loadItemLength = area.find('.loadItem').length,
        setAdj = (dataLengh)-(loadItemLength),
        setBeg = (dataLengh)-(setAdj);
        function blogList(number){
          var title = data[number].title;
          var link = data[number].link;
          var archiveDate = data[number].date;
              archiveDate = archiveDate.match(/(\d{4})-(\d{2})/);
              archiveDate = RegExp.$1 + RegExp.$2;
          var category = '';

          for (var j = data[number].terms.category.length - 1; j >= 0; j--) {
            category += data[number].terms.category[j].name;
          }
          var pubDD = new Date(data[i].date);
          yy = pubDD.getYear();if (yy < 2000) { yy += 1900; }
          mm = pubDD.getMonth() + 1;dd = pubDD.getDate();
          var pubDate = yy +'年'+ mm +'月'+ dd +'日';
          var html = '<div class="p-blogListBox loadItem"><a href="' + link + '">'+ '<p class="p-blogListBox__category">' + category +'</p>' + '<p class="is-read__txt c-fade"><span>続きを見る</span></p><h2 class="p-blogListBox__title">' + title +'</h2><p class="p-blogListBox__date">'+ pubDate + '</p></a></div>';
          if(area == setArea_archive){
            if(archiveDate === url){
              $(html).appendTo(area).css({opacity:'0'}).animate({opacity:'1'},fadeSpeed);
            }
          } else {
            $(html).appendTo(area).css({opacity:'0'}).animate({opacity:'1'},fadeSpeed);
          }

          cutTextEvent();
          mouseoverEvent();
        }
        if(!(dataLengh == loadItemLength)){
          area.append('<div id="nowLoading" class="c-loading fa fa-refresh fa-spin"></div>');
          if(loadItemLength == 0){
            if(dataLengh <= loadNum){
              for (var i=0; i<dataLengh; i++) {
                blogList(i);
              }
              setMore.remove();
              setMore_top.remove();
              setMore_archive.remove();
            } else {
              for (var i=0; i<loadNum; i++) {
                blogList(i);
              }
            }
          } else if(loadItemLength > 0 && loadItemLength < dataLengh){
            if(loadNum < setAdj){
              for (var i=0; i<loadNum; i++) {
                v = i+setBeg;
                blogList(v);
              }
            } else if(loadNum >= setAdj){
              for (var i=0; i<setAdj; i++) {
                v = i+setBeg;
                blogList(v);
              }
              setMore.remove();
              setMore_top.remove();
              setMore_archive.remove();
            }
          } else if(loadItemLength == dataLengh){
            return false;
          }
        } else {
          return false;
        }
      }, //success
      complete : function(){
        $('#nowLoading').each(function(){
          $(this).remove();
        });
        return false;
      } //complete
    });
    return false;
  }

  setMore.click(function(){
    ajax_list(category_urls,setArea);
  }).click();
  setMore_top.click(function(){
    ajax_list(post_url,setArea_page);
  }).click();
  setMore_archive.click(function(){
    ajax_list(post_url,setArea_archive);
  }).click();
  cutTextEvent();
  mouseoverEvent();
});