var $ = require( "./../../../../bower_components/jquery/dist/jquery.js" );
var easing = require( 'jquery-easing');
var newMark = function (opt){
  opt = opt || {};
  opt.targetClassName = opt.targetClassName || '.p-newsArea__date';
  opt.targetDate = opt.targetDate || '30日';
    $(function(){
        var currentDate = new Date();
        $(opt.targetClassName).each(function(){
            var NewDate = opt.targetDate;
            NewDate = NewDate.replace(/日/, '');
            var entry = NewDate * 24;
            // var pass = entry; // MTタグを入れる（30日は720：40日は960：50日は1200）
            var newmarkAttr = $(this).attr('data-role');
            newmarkAttr = newmarkAttr.replace(/年|月|日|時|分/g,':');
            var time = newmarkAttr.split(":");
            var entryDate = new Date(time[0], time[1]-1, time[2], time[3], time[4]);
            var now = (currentDate.getTime() - entryDate.getTime())/(60*60*1000);
            now = Math.ceil(now);
            if(now <= entry){
                $(this).addClass('is-new__icon');
            }
        });
    });
};
module.exports = newMark;