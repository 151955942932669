// var googleMapAPI = function (){
function initialize() {
  var latlng = new google.maps.LatLng(43.075727, 141.313950);
  var myOptions = {
    zoom: 16,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    draggable: false,
    center: latlng,
    mapTypeControlOptions: { mapTypeIds: ['sample', google.maps.MapTypeId.ROADMAP] }
  };
  var map = new google.maps.Map(document.getElementById('map_canvas'), myOptions);

  var icon = new google.maps.MarkerImage('/wp-content/themes/massimo-f/common/images/icon_map.png',
    new google.maps.Size(102,52),
    new google.maps.Point(0,0)
    );
  var markerOptions = {
    position: latlng,
    map: map,
    icon: icon,
    title: 'マッシモ'
  };
  var marker = new google.maps.Marker(markerOptions);

  var styleOptions = [
    {
      "stylers": [
        { "saturation": -100 },
        { "visibility": "simplified" },
        { "lightness": 22 }
      ]
    }
  ];

  var styledMapOptions = { name: 'マッシモ' };
  var sampleType = new google.maps.StyledMapType(styleOptions, styledMapOptions);
  map.mapTypes.set('sample', sampleType);
  map.setMapTypeId('sample');
}

google.maps.event.addDomListener(window, 'load', initialize);
// };
// module.exports = googleMapAPI;