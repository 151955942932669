var $ = require( "./../../../bower_components/jquery/dist/jquery.js" );
var easing = require( 'jquery-easing');
// var polyfilter_scriptpath = "<?php bloginfo('template_directory'); ?>/common/js/";
// var contentloaded = require( './lib/contentloaded.js' );
// var cssParser = require( './lib/cssParser.js' );
// var cssfilterspolyfill = require( './lib/css-filters-polyfill.js' );
var sub = require( './utility.js' );
/*
================================================================================
* javascript information
* file name  : main.js (トップページ固有のスクリプト)
================================================================================
*/
/* ============================
 *
  mainSlider
 *
============================ */
var mainSlider = require( './vendor/mainSlider.js' );
mainSlider();
/* ============================
 *
  sp Navigation
 *
============================ */
var spNav = require( './vendor/spNav.js' );
spNav({
  className: "#sp-nav__btn",
  targetName: ".l-navInner"
});
/* ============================
 *
  new Mark
 *
============================ */
var newMark = require( './vendor/newMark.js' );
newMark({
  targetClassName: ".p-newsArea__date",
  targetDate: "4日"
});
/* ============================
 *
  Modal Window & Flick Slider
 *
============================ */
var shopModalSlider_spFlick = require( './vendor/shopModalSlider_spFlick.js' );
/* ============================
 *
  Google Map API
 *
============================ */
var googleMapAPI = require( './vendor/googleMapAPI.js' );

