var $ = require( "./../../../../bower_components/jquery/dist/jquery.js" );
var easing = require( 'jquery-easing');
var spNav = function (opt){
  opt = opt || {};
  opt.className = opt.className || '#sp-nav__btn';
  opt.targetName = opt.targetName || '.l-navInner';
  $(function(){
    $(opt.className).click(function(){
      $(opt.targetName).slideToggle();
      return false;
    });
    $(window).resize(function(){
      var win = $(window).width();
      var p = 748;
      if(win > p){
        $(opt.targetName).show();
      } else {
        $(opt.targetName).hide();
      }
    });
  });
};
module.exports = spNav;